.PlantingAttributes {
  border: 2px solid darkgray;
  padding: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}
.PlantingAttributes .left-column {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1em;
  align-items: center;
}
.PlantingAttributes .right-column textarea {
  width: 100%;
  height: 180px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF8xYmI4ZTVmZC9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3BsYW50aW5nL3Bsb3R0ZWQiLCJzb3VyY2VzIjpbIlBsYW50aW5nQXR0cmlidXRlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBUUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLlBsYW50aW5nQXR0cmlidXRlcyB7XG4gIGJvcmRlcjogMnB4IHNvbGlkIGRhcmtncmF5O1xuICBwYWRkaW5nOiAxMHB4O1xuICB3aWR0aDogMTAwJTtcbiAgZGlzcGxheTogZ3JpZDtcbiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiAxZnIgMWZyO1xuICBnYXA6IDFlbTtcblxuICAubGVmdC1jb2x1bW4ge1xuICAgIGRpc3BsYXk6IGdyaWQ7XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBtYXgtY29udGVudCAxZnI7XG4gICAgZ2FwOiAxZW07XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgfVxuICAucmlnaHQtY29sdW1uIHtcbiAgICAvLyBkaXNwbGF5OiBncmlkO1xuICAgIC8vIGdyaWQtdGVtcGxhdGUtY29sdW1uczogbWF4LWNvbnRlbnQgMWZyO1xuICAgIC8vIGdhcDogMWVtO1xuICAgIC8vIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgLy8gbWFyZ2luOiAwIGF1dG87XG4gICAgdGV4dGFyZWEge1xuICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICBoZWlnaHQ6IDE4MHB4O1xuICAgIH1cbiAgfVxufVxuIl19 */