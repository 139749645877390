// Can be used as a controlled or uncontrolled component

import React from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'
import { parseRailsInput } from './parseRailsInput'
import { queryString } from '../../shared/utils'

const SelectAsyncPaginatedRansack = ({
  classNamePrefix,
  defaultValue,
  disabled,
  extraFields,
  extraPredicates,
  input,
  inputId,
  inputName,
  isClearable,
  isMulti,
  json_data,
  label,
  maxMenuHeight,
  model_name_plural,
  onChange,
  options,
  optionValue,
  ransack_predicate,
  select,
  styles,
  value,
  writeable,
}) => {
  const itemsPerPage = 25
  const parsedInput = parseRailsInput(input)

  const providedValues = [value, defaultValue].filter(
    (x) => typeof x === 'string' || (typeof x === 'object' && x != null)
  )
  if (providedValues.length === 0)
    console.error(
      'SelectAsyncPaginatedRansack: missing value or defaultValue (may be empty string for blank)'
    )
  else if (providedValues.length === 2)
    console.error(
      'SelectAsyncPaginatedRansack: provide value or defaultValue but not both'
    )

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const params = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      json_data,
      filters: {
        [ransack_predicate]: searchQuery,
        ...(extraPredicates || {}),
      },
      writeable,
      select,
      extra_fields: [label, optionValue, ...extraFields],
    }

    try {
      const response = await fetch(
        `/${model_name_plural}/ransack.json?${queryString(params)}`
      )

      const responseJSON = await response.json()

      return {
        options: responseJSON,
        hasMore: responseJSON.length === itemsPerPage,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error('FETCH ERROR:', error)
      return { options: [], hasMore: false, additional: { page: page + 1 } }
    }
  }

  return (
    <div className="SelectAsyncPaginatedRansack">
      <AsyncPaginate
        additional={{ page: 1 }}
        cacheOptions
        cacheUniqs={[
          model_name_plural,
          ransack_predicate,
          JSON.stringify(extraPredicates),
        ]}
        classNamePrefix={classNamePrefix}
        defaultValue={defaultValue} // when used as an uncontrolled component (ie directly from a haml form)
        isDisabled={disabled}
        getOptionLabel={(e) => e[label]}
        getOptionValue={(e) => e[optionValue]}
        id={parsedInput && parsedInput.id}
        inputId={inputId}
        isClearable={isClearable}
        isMulti={isMulti}
        loadOptions={loadOptions}
        maxMenuHeight={maxMenuHeight}
        name={inputName || (parsedInput && parsedInput.name)}
        onChange={onChange}
        options={options || undefined}
        styles={styles}
        value={value}
      />
    </div>
  )
}

SelectAsyncPaginatedRansack.propTypes = {
  input: PropTypes.string,
  inputName: PropTypes.string,
  json_data: PropTypes.bool,
  ransack_predicate: PropTypes.string.isRequired,
  extraPredicates: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  writeable: PropTypes.bool,
  select: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  model_name_plural: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOf([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
    '',
  ]),
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  styles: PropTypes.object,
  isClearable: PropTypes.bool,
  extraFields: PropTypes.arrayOf(PropTypes.string),
}

SelectAsyncPaginatedRansack.defaultProps = {
  defaultValue: null,
  extraFields: [],
  extraPredicates: {},
  input: undefined,
  inputName: undefined,
  isClearable: true,
  isMulti: false,
  json_data: false,
  options: [],
  onChange: undefined,
  select: undefined,
  styles: {},
  writeable: false,
}

export default SelectAsyncPaginatedRansack
